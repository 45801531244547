// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-studies-details-data-quality-js": () => import("./../../src/pages/case-studies-details-data-quality.js" /* webpackChunkName: "component---src-pages-case-studies-details-data-quality-js" */),
  "component---src-pages-case-studies-details-js": () => import("./../../src/pages/case-studies-details.js" /* webpackChunkName: "component---src-pages-case-studies-details-js" */),
  "component---src-pages-case-studies-details-metadata-management-js": () => import("./../../src/pages/case-studies-details-metadata-management.js" /* webpackChunkName: "component---src-pages-case-studies-details-metadata-management-js" */),
  "component---src-pages-case-studies-details-rdm-js": () => import("./../../src/pages/case-studies-details-rdm.js" /* webpackChunkName: "component---src-pages-case-studies-details-rdm-js" */),
  "component---src-pages-case-studies-js": () => import("./../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-history-js": () => import("./../../src/pages/history.js" /* webpackChunkName: "component---src-pages-history-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membership-levels-js": () => import("./../../src/pages/membership-levels.js" /* webpackChunkName: "component---src-pages-membership-levels-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-service-details-abinitio-js": () => import("./../../src/pages/service-details-abinitio.js" /* webpackChunkName: "component---src-pages-service-details-abinitio-js" */),
  "component---src-pages-service-details-discovery-js": () => import("./../../src/pages/service-details-discovery.js" /* webpackChunkName: "component---src-pages-service-details-discovery-js" */),
  "component---src-pages-service-details-js": () => import("./../../src/pages/service-details.js" /* webpackChunkName: "component---src-pages-service-details-js" */),
  "component---src-pages-service-details-processing-js": () => import("./../../src/pages/service-details-processing.js" /* webpackChunkName: "component---src-pages-service-details-processing-js" */),
  "component---src-pages-service-details-science-js": () => import("./../../src/pages/service-details-science.js" /* webpackChunkName: "component---src-pages-service-details-science-js" */),
  "component---src-pages-service-details-testing-js": () => import("./../../src/pages/service-details-testing.js" /* webpackChunkName: "component---src-pages-service-details-testing-js" */),
  "component---src-pages-services-js": () => import("./../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

